import FetchRequest from "@/api/FetchRequest";

class DeptApi {
  /**
   * 获取父部门
   * @param deptId 部门ID
   */
  static parent(deptId: any): Promise<any> {
	  console.log("parent==>>",deptId);
    return FetchRequest.get("/j2api/system/dept/parent/" + deptId, true);
  }

  /**
   * 获取所有部门
   */
  static list(): Promise<any> {
    return FetchRequest.get("/j2api/system/dept/treeselect", true);
  }

  /**
   * 获取部门
   * @param id 部门ID
   */
  static get(id: string): Promise<any> {
    return FetchRequest.get("/j2api/system/dept/getInfo/" + id, true);
  }

  /**
   * 获取部门用户
   * @param deptId 部门ID
   */
  static users(deptId: string): Promise<any> {
    return FetchRequest.get("/j2api/system/dept/" + deptId + "/users", true);
  }

  /**
   * 获取部门人数
   */
  static count(): Promise<any> {
    return FetchRequest.get("/j2api/system/dept/count", true);
  }
}

export default DeptApi;
